import React from "react";

function WhoWeAre() {
  return (
    <section className="max-w-8xl mx-auto py-12 md:mt-8 px-4 sm:px-6 lg:px-24">

      <div className="flex flex-col md:flex-row items-center gap-8 pt-5">
        <div className="w-full md:w-1/2 order-2">
          <div className="my-auto md:mx-auto w-[80%]">
            <h2 className="md:text-5xl text-2xl font-semibold mb-4">
              Our Mission
            </h2>
            <p className="md:text-xl text-md mb-4">
              At AiNDREA, our mission is to empower creative professionals by providing a secure, transparent, and lucrative marketplace for digital assets. We leverage Web3 technologies to ensure creators receive the recognition and revenue they deserve while fostering a vibrant community of innovators and investors.
            </p>

          </div>
        </div>
        <div className="w-full md:w-1/2 order-1">
          <div className="w-full relative flex justify-center">
            <img
              src="/assets/images/aboutus/1.webp"
              alt="How it works"
              className="me-auto rounded-3xl object-cover max-h-[500px] w-[100%]"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-center gap-8 my-8 pt-5">
        <div className="w-full md:w-1/2 order-1 md:order-2">
          <div className="w-full relative flex justify-center">
            <img
              src="/assets/images/aboutus/2.webp"
              alt="How it works"
              className="rounded-3xl ms-auto object-cover max-h-[500px] w-[100%]"
            />
          </div>
        </div>
        <div className="w-full md:w-1/2 order-2 md:order-1">
          <div className="my-auto md:mx-auto w-[80%]">
            <h2 className="md:text-5xl text-2xl font-semibold mb-4">
              Our Vision
            </h2>
            <p className="md:text-xl text-md mb-4">
              To revolutionize the digital asset marketplace through blockchain integration, enabling seamless IP protection, fair revenue distribution, and unparalleled community collaboration. We aim to become the go-to platform for creators, collaborators, and investors seeking to thrive in the digital economy.
            </p>

          </div>
        </div>

      </div>
      <div className="flex flex-col md:flex-row items-center gap-8 pt-5">
        <div className="w-full md:w-1/2 order-2">
          <div className="my-auto md:mx-auto w-[80%]">
            <h2 className="md:text-5xl text-2xl font-semibold mb-4">
              Our Journey
            </h2>
            <p className="md:text-xl text-md mb-4">
              Founded in 2024, AiNDREA emerged from the need to create a more equitable digital marketplace. Recognizing the challenges faced by creators in monetizing and protecting their work, our team set out to build a platform that not only addresses these issues but also fosters a collaborative and supportive community. From our humble beginnings to our current state, we continue to innovate and expand to meet the evolving needs of our users.
            </p>

          </div>
        </div>
        <div className="w-full md:w-1/2 order-1">
          <div className="w-full relative flex justify-center">
            <img
              src="/assets/images/aboutus/3.webp"
              alt="How it works"
              className="me-auto rounded-3xl object-cover max-h-[500px] w-[100%]"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhoWeAre;
