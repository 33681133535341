import React from "react";
import Navbar from "../components/common/preRegistration/Navbar";
import Footer from "../components/common/preRegistration/Footer";

function PrivacyPolicy() {
  return (
    <>
      <Navbar />
      <section className="px-4 sm:px-6 lg:px-24 max-w-8xl mx-auto my-14">
        <h1 className="text-center font-bold md:text-5xl text-2xl mb-10">
          <span className="text-[#CA63CE]">Privacy </span> and Policy
        </h1>
        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">Introduction</h2>
          <p>
            At AiNDREA ("we," "us," or "our"), we are dedicated to safeguarding
            your personal information and your right to privacy. This Privacy
            Policy outlines how we collect, use, disclose, and protect your
            personal data when you visit our websites or utilize our services.
          </p>
          <p>
            By accessing our website or using our services, you acknowledge that
            you have read, understood, and agree to our collection, storage,
            use, and disclosure of your personal information as described in
            this Privacy Policy. If you do not agree, please discontinue the use
            of our website and services.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">
            1. Information We Collect
          </h2>
          <h3 className="text-lg font-medium mt-4">1.1 Personal Information</h3>
          <ul className="list-disc pl-6">
            <li>
              Register for an account: Including username, password, and contact
              information.
            </li>
            <li>Subscribe to our newsletter: Email address and preferences.</li>
            <li>
              Request information or quotes: Name, email, phone number, company
              details, and specific service interests.
            </li>
            <li>
              Contact customer support: Name, contact details, and the content
              of your communication.
            </li>
            <li>
              Participate in surveys or promotions: Demographic information and
              survey responses.
            </li>
          </ul>
          <h3 className="text-lg font-medium mt-4">
            1.2 Automatically Collected Information
          </h3>
          <ul className="list-disc pl-6">
            <li>
              Log Data: IP address, browser type, browser version, pages
              visited, time and date of visit, time spent on pages, and
              referring URLs.
            </li>
            <li>
              Device Information: Type of device, operating system, unique
              device identifiers, and mobile network information.
            </li>
            <li>
              Location Information: Approximate location based on IP address.
            </li>
          </ul>
          <h3 className="text-lg font-medium mt-4">
            1.3 Cookies and Similar Technologies
          </h3>
          <p>
            We use cookies, web beacons, and other tracking technologies to
            collect information about your interactions with our website. This
            helps us provide a personalized experience and analyze site traffic.
            For detailed information, please refer to our Cookie Policy.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">
            2. How We Use Your Information
          </h2>
          <h3 className="text-lg font-medium mt-4">2.1 Service Delivery</h3>
          <ul className="list-disc pl-6">
            <li>
              To provide and maintain our services: Ensuring our website
              functions correctly and delivering requested services.
            </li>
            <li>
              Account management: Managing user registrations, authentications,
              and account settings.
            </li>
          </ul>
          <h3 className="text-lg font-medium mt-4">2.2 Communication</h3>
          <ul className="list-disc pl-6">
            <li>
              Administrative communications: Sending confirmations, technical
              notices, updates, security alerts, and support messages.
            </li>
            <li>
              Marketing communications: Informing you about promotions, upcoming
              events, and news. You can opt-out at any time.
            </li>
          </ul>
          <h3 className="text-lg font-medium mt-4">
            2.3 Personalization and Analytics
          </h3>
          <ul className="list-disc pl-6">
            <li>
              Personalize user experience: Tailoring content and services
              according to your preferences.
            </li>
            <li>
              Performance analysis: Monitoring and analyzing usage to improve
              our website and services.
            </li>
          </ul>
          <h3 className="text-lg font-medium mt-4">
            2.4 Legal Compliance and Security
          </h3>
          <ul className="list-disc pl-6">
            <li>
              Compliance: Fulfilling legal obligations, such as tax and
              accounting requirements.
            </li>
            <li>
              Protection: Detecting, preventing, and addressing technical issues
              and potential security breaches.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">
            3. Disclosure of Your Information
          </h2>
          <h3 className="text-lg font-medium mt-4">
            3.1 With Service Providers
          </h3>
          <p>
            We may employ third-party companies and individuals to facilitate
            our services, provide services on our behalf, perform
            service-related tasks, or assist us in analyzing how our services
            are used. These third parties have access to your personal
            information only to perform these tasks on our behalf and are
            obligated not to disclose or use it for any other purpose.
          </p>
          <h3 className="text-lg font-medium mt-4">3.2 Business Transfers</h3>
          <p>
            In the event of a merger, acquisition, or asset sale, your personal
            information may be transferred. We will provide notice before your
            personal information is transferred and becomes subject to a
            different Privacy Policy.
          </p>
          <h3 className="text-lg font-medium mt-4">3.3 Legal Obligations</h3>
          <p>
            We may disclose your personal information if required to do so by
            law or in response to valid requests by public authorities (e.g., a
            court or government agency).
          </p>
          <h3 className="text-lg font-medium mt-4">3.4 Protection of Rights</h3>
          <p>
            We may disclose your information to enforce our Terms of Service,
            protect and defend our rights or property, prevent or investigate
            possible wrongdoing, protect the personal safety of users or the
            public, and protect against legal liability.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">4. Data Security</h2>
          <p>
            We prioritize the security of your personal information and
            implement industry-standard measures to protect it, including
            encryption, secure storage, regular security audits, and strict
            access controls. Despite our efforts, no method of transmission over
            the internet or electronic storage is 100% secure.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">5. Data Retention</h2>
          <p>
            We retain your personal information only for as long as necessary to
            fulfill the purposes for which it was collected, including legal
            compliance, dispute resolution, and operational needs. When no
            longer required, we will securely delete or anonymize it.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">6. Your Rights</h2>
          <p>
            Depending on your jurisdiction, you may have rights such as access,
            correction, deletion, restriction, objection, and data portability.
            To exercise these rights, please contact us at:
          </p>
          <p>
            <strong>Email:</strong> team@aindrea.com
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">
            7. International Data Transfers
          </h2>
          <p>
            Your information may be transferred to and processed in countries
            other than your own. We ensure appropriate safeguards are in place
            to protect your personal data when it is transferred
            internationally.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">
            8. Third-Party Links and Services
          </h2>
          <p>
            Our website may contain links to third-party websites, plug-ins, and
            applications. We do not control these third-party websites and are
            not responsible for their privacy statements.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">9. Children's Privacy</h2>
          <p>
            Our services are not intended for individuals under the age of 13.
            If you are aware that a child has provided us with personal data,
            please contact us so we can take appropriate action.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">
            10. Changes to This Privacy Policy
          </h2>
          <p>
            We may update our Privacy Policy from time to time. We will notify
            you of any significant changes by posting the new Privacy Policy on
            this page and updating the "Last updated" date at the top.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-2">11. Contact Us</h2>
          <p>
            If you have any questions or concerns about this Privacy Policy or
            our data practices, please contact us at:
          </p>
          <p>
            <strong>Email:</strong> team@aindrea.com
          </p>
        </section>
      </section>

      <Footer />
    </>
  );
}

export default PrivacyPolicy;
