import React, { useState } from "react";
import ButtonLoader from "../common/ButtonLoader";
import { singleFileUpload } from "../../api/FileUpload";
import toast from "react-hot-toast";
import { supportForm } from "../../api/UserContact";

function SubmitForm() {
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [credentials, setCredentials] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    attachment: "",
  });

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFileName(selectedFile.name);
      setLoading(true);
      const formData = new FormData();
      formData.append("file", selectedFile);

      try {
        const result = await singleFileUpload(formData);
        if (result && result.data) {
          setCredentials((prev) => ({
            ...prev,
            attachment: result.data,
          }));
          toast.success("File uploaded successfully!");
        }
      } catch (error) {
        console.error("File upload failed:", error);
        toast.error("File upload failed.");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSupport = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const result = await supportForm({ ...credentials });
      if (result && result.status === "success") {
        setCredentials({
          name: "",
          email: "",
          subject: "",
          message: "",
          attachment: "",
        });
        setFileName(""); 
        toast.success(result.message || "Message successfully submitted!");
      } else {
        toast.error(result.message || "Failed to send the message.");
      }
    } catch (error) {
      toast.error("An error occurred while submitting the request.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="max-w-8xl mx-auto py-12 px-4 sm:px-6 lg:px-24">
      <div className="flex flex-col md:flex-row items-center gap-8">
        <div className="w-full md:w-1/2 md:order-1">
          <div className="w-full">
            <img
              src="/assets/images/support/submit.webp"
              alt="Get Started"
              className="rounded-3xl object-cover md:w-[90%] w-[100%]"
            />
          </div>
        </div>
        <div className="w-full md:w-1/2 md:order-1">
          <div className="my-auto">
            <h2 className="text-2xl md:text-3xl font-bold">
              Need More <span className="text-primary">Help?</span>
            </h2>
            <p className="text-gray-600 my-4">
              If you couldn't find the answer to your question, submit a support
              ticket, and our team will assist you shortly.
            </p>
            <form onSubmit={handleSupport}>
              <div className="grid grid-cols-1 gap-7 my-6">
                <div className="space-y-2">
                  <label htmlFor="name" className="font-medium text-lg">
                    Name
                  </label>
                  <input
                    id="name"
                    placeholder="Enter your full name"
                    className="bg-[#fbf5fb] p-3 rounded w-full outline-primary text-black"
                    required
                    name="name"
                    value={credentials.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="space-y-2">
                  <label htmlFor="email" className="font-medium text-lg">
                    Email Address
                  </label>
                  <input
                    id="email"
                    placeholder="Enter your email address"
                    className="bg-[#fbf5fb] p-3 rounded w-full outline-primary text-black"
                    required
                    name="email"
                    value={credentials.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="space-y-2">
                  <label htmlFor="subject" className="font-medium text-lg">
                    Subject
                  </label>
                  <input
                    id="subject"
                    placeholder="Enter the subject of your message"
                    className="bg-[#fbf5fb] p-3 rounded w-full outline-primary text-black"
                    required
                    name="subject"
                    value={credentials.subject}
                    onChange={handleChange}
                  />
                </div>
                <div className="space-y-2">
                  <label htmlFor="description" className="font-medium text-lg">
                    Message
                  </label>
                  <textarea
                    id="description"
                    placeholder="Write your message here"
                    rows="3"
                    className="bg-[#fbf5fb] p-3 rounded w-full outline-primary text-black"
                    required
                    name="message"
                    value={credentials.message}
                    onChange={handleChange}
                  />
                </div>
                <div className="space-y-2">
                  <label htmlFor="file" className="font-medium text-lg">
                    Attachment (s)
                  </label>
                  <label htmlFor="file" className="block">
                    <div className="relative flex items-center justify-center">
                      <input
                        type="file"
                        id="attachment"
                        name="attachment"
                        className="opacity-0 absolute inset-0 z-50 cursor-pointer"
                        onChange={handleFileChange}
                        required
                      />
                      <div className="bg-[#fbf5fb] p-3 w-full text-center rounded text-secondary cursor-pointer">
                        Choose File
                      </div>
                    </div>
                  </label>
                  {fileName && (
                    <p className="text-sm text-gray-500">Uploaded file: {fileName}</p> // Show file name here
                  )}
                </div>
              </div>
              <div>
                <button
                  className="flex items-center justify-center w-full bg-primary text-white py-3 text-lg rounded-3xl hover:bg-black"
                  disabled={loading}
                >
                  {loading ? <ButtonLoader /> : "Submit a Ticket"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SubmitForm;
