import React, { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { useSwipeable } from "react-swipeable"; // Import the swipeable hook

const testimonials = [
  {
    id: 1,
    name: "Alice Johnson",
    role: "CEO, TechCorp",
    content:
      "This product has revolutionized our workflow. It's intuitive, powerful, and a joy to use every day.",
    avatar: "assets/images/testimonials/test1.png",
    bgImage: "left-frame.svg",
  },
  {
    id: 2,
    name: "Jenny Smith",
    role: "Designer, CreativeCo",
    content:
      "I've tried many similar tools, but this one stands out. It's helped me boost my productivity tenfold.",
    avatar: "assets/images/testimonials/test2.png",
    bgImage: "main-frame.svg",
  },
  {
    id: 3,
    name: "Carol Davis",
    role: "Freelancer",
    content:
      "As a freelancer, this tool is a game-changer. It's like having a personal assistant for all my projects.",
    avatar: "assets/images/testimonials/test3.png",
    bgImage: "right-frame.png",
  },
];

function Testimonials() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  // Detect if the screen size is mobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Treat < 768px as mobile
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const totalSlides = isMobile
    ? testimonials.length
    : Math.ceil(testimonials.length / 3);

  const nextTestimonials = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalSlides);
  };

  const prevTestimonials = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + totalSlides) % totalSlides);
  };

  // Use the swipeable hook for swipe gestures
  const handlers = useSwipeable({
    onSwipedLeft: nextTestimonials, // Swipe left to go to next
    onSwipedRight: prevTestimonials, // Swipe right to go to previous
    trackMouse: true, // Optional: allows swipe functionality with a mouse as well
  });

  const testimonialStyle = (totalVisible) => ({
    minWidth: `${100 / totalVisible}%`, // Width dynamically based on visible slides
  });

  const bgStyle = (img) => ({
    backgroundImage: `url('/assets/images/testimonials/${img}')`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: "100%",
    minHeight: "500px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  });

  const textStyle = {
    boxSizing: "border-box", // Include padding in width calculation
    maxWidth: "60%", // Adjust width for mobile view
    overflowWrap: "break-word", // Ensure long words are broken
    whiteSpace: "normal", // Prevent text from exceeding the div by forcing a single line
    textAlign: "center", // Optional: to keep text centered
  };

  return (
    <div className="px-5 md:px-24 relative mb-16" id="testimonials">
      <div className="flex justify-center gap-10">
        <button className="p-0 m-0" onClick={prevTestimonials}>
          <ChevronLeft color="#CA63CE" size={50} />
        </button>
        <h1 className="text-4xl text-center md:text-5xl font-bold">
          What Our <span className="text-[#CA63CE]">Members</span> Say
        </h1>
        <button className="p-0 m-0" onClick={nextTestimonials}>
          <ChevronRight color="#CA63CE" size={50} />
        </button>
      </div>

      {/* Web View: 3 Cards per slide */}
      {!isMobile && (
        <div className="overflow-hidden mt-20">
          <div
            style={{
              display: "flex",
              transition: "transform 0.5s ease-in-out",
              transform: `translateX(-${currentIndex * 100}%)`,
            }}
          >
            {testimonials.map((testimonial) => (
              <div
                className="flex justify-center"
                key={testimonial.id}
                style={testimonialStyle(3)} // Show 3 cards per slide
              >
                <div className="relative" style={bgStyle(testimonial.bgImage)}>
                  <h1 className="text-[#CA63CE] font-bold text-xl">
                    {testimonial.name}
                  </h1>
                  <p>{testimonial.role}</p>
                  <img
                    className="w-[25%] mt-1 mb-3"
                    src="assets/images/testimonials/rating.png"
                    alt="rating"
                  />
                  <p className="text-sm" style={textStyle}>
                    {testimonial.content}
                  </p>
                  <img
                    className="absolute w-[100px] top-[20px] right-[40%]"
                    src={testimonial.avatar}
                    alt={testimonial.name}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Mobile View: 1 Card per slide with swipe handlers */}
      {isMobile && (
        <div className="overflow-hidden mt-20" {...handlers}>
          <div
            style={{
              display: "flex",
              transition: "transform 0.5s ease-in-out",
              transform: `translateX(-${currentIndex * 100}%)`,
            }}
          >
            {testimonials.map((testimonial) => (
              <div
                className="flex justify-center"
                key={testimonial.id}
                style={testimonialStyle(1)} // Show 1 card per slide on mobile
              >
                <div className="relative" style={bgStyle(testimonial.bgImage)}>
                  <h1 className="text-[#CA63CE] font-bold text-xl">
                    {testimonial.name}
                  </h1>
                  <p>{testimonial.role}</p>
                  <img
                    className="w-[25%] mt-1 mb-3"
                    src="assets/images/testimonials/rating.png"
                    alt="rating"
                  />
                  <p className="text-sm" style={textStyle}>
                    {testimonial.content}
                  </p>
                  <img
                    className="absolute w-[100px] top-[20px] right-[40%]"
                    src={testimonial.avatar}
                    alt={testimonial.name}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="flex justify-center absolute md:top-[90px] md:left-[49%] top-[150px] left-[35%]">
        {Array.from({ length: totalSlides }).map((_, index) => (
          <div
            key={index}
            className={`w-3 h-3 rounded-full mx-1 ${
              index === currentIndex
                ? "bg-gradient-to-r from-purple-400 via-purple-300 to-green-500"
                : "bg-gray-300"
            }`}
            aria-hidden="true"
          />
        ))}
      </div>
    </div>
  );
}

export default Testimonials;
