import React from "react";
import Navbar from "../components/common/preRegistration/Navbar";
import Footer from "../components/common/preRegistration/Footer";

function TermsAndConditions() {
  return (
    <>
      <Navbar />
      <section className="px-4 sm:px-6 lg:px-24 max-w-8xl mx-auto my-14">
        <h1 className="text-center font-bold md:text-5xl text-2xl mb-10">
          <span className="text-[#CA63CE]">Terms </span> of Service
        </h1>
        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">Acceptance of Terms</h2>
          <p>
            These Terms of Service ("Terms") govern your access to and use of
            our websites and any related services provided by AiNDREA ("we,"
            "us," or "our"). By accessing or using our website and services, you
            agree to be bound by these Terms. If you do not agree, please
            refrain from using our website and services.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">Definitions</h2>
          <ul className="list-disc pl-6">
            <li>
              <strong>User or you:</strong> Any individual or entity accessing
              or using our website or services.
            </li>
            <li>
              <strong>Services:</strong> All services provided by AiNDREA,
              including consulting, assessments, training, and access to our
              website.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">
            Use of the Website and Services
          </h2>
          <h3 className="text-lg font-medium mt-4">3.1 Eligibility</h3>
          <p>
            You must be at least 18 years old to use our services. By using our
            website, you represent and warrant that you meet this requirement.
          </p>
          <h3 className="text-lg font-medium mt-4">3.2 User Account</h3>
          <ul className="list-disc pl-6">
            <li>
              <strong>Registration:</strong> You may need to create an account
              to access certain services.
            </li>
            <li>
              <strong>Account Security:</strong> You are responsible for
              maintaining the confidentiality of your login credentials.
            </li>
            <li>
              <strong>Account Information:</strong> Ensure that all information
              provided is accurate and up-to-date.
            </li>
          </ul>
          <h3 className="text-lg font-medium mt-4">3.3 Prohibited Uses</h3>
          <ul className="list-disc pl-6">
            <li>Use the website for any unlawful purpose.</li>
            <li>
              Attempt to gain unauthorized access to any portion of the website.
            </li>
            <li>
              Introduce viruses, trojans, worms, or other malicious material.
            </li>
            <li>
              Exploit the website for commercial purposes without our consent.
            </li>
            <li>
              Engage in any activity that disrupts or interferes with the
              website's functionality.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">
            Intellectual Property Rights
          </h2>
          <h3 className="text-lg font-medium mt-4">4.1 Ownership</h3>
          <p>
            All content on the website, including text, graphics, logos, images,
            and software, is the property of AiNDREA or its content suppliers
            and is protected by intellectual property laws.
          </p>
          <h3 className="text-lg font-medium mt-4">4.2 License</h3>
          <p>
            We grant you a limited, non-exclusive, non-transferable license to
            access and use the website for personal or internal business
            purposes.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">
            Disclaimer of Warranties
          </h2>
          <h3 className="text-lg font-medium mt-4">5.1 "As Is" Basis</h3>
          <p>
            Our services are provided on an "as is" and "as available" basis
            without warranties of any kind, either express or implied.
          </p>
          <h3 className="text-lg font-medium mt-4">5.2 No Guarantees</h3>
          <p>
            The website will meet your requirements. The service will be
            uninterrupted, timely, secure, or error-free. The results obtained
            will be accurate or reliable.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">
            Limitation of Liability
          </h2>
          <h3 className="text-lg font-medium mt-4">6.1 Exclusion of Damages</h3>
          <p>
            To the fullest extent permitted by law, AiNDREA shall not be liable
            for any indirect, incidental, special, consequential, or punitive
            damages arising out of or related to your use or inability to use
            the website or services.
          </p>
          <h3 className="text-lg font-medium mt-4">6.2 Maximum Liability</h3>
          <p>
            Our total liability to you for all claims arising from or related to
            the use of the website or services shall not exceed the amounts you
            have paid to us in the last six months.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">Indemnification</h2>
          <p>
            You agree to indemnify, defend, and hold harmless AiNDREA and its
            affiliates, officers, agents, and employees from any claims,
            liabilities, damages, losses, or expenses arising from your use of
            the website or violation of these Terms.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">Termination</h2>
          <h3 className="text-lg font-medium mt-4">8.1 By Us</h3>
          <p>
            We reserve the right to terminate or suspend your access to the
            website or services at our sole discretion, without notice, for
            conduct that we believe violates these Terms or is harmful to other
            users or us.
          </p>
          <h3 className="text-lg font-medium mt-4">
            8.2 Effect of Termination
          </h3>
          <p>
            Upon termination: All rights granted to you under these Terms will
            cease. You must cease all use of the website and services. Sections
            that by their nature should survive termination shall continue to be
            in effect.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">Miscellaneous</h2>
          <h3 className="text-lg font-medium mt-4">9.1 Entire Agreement</h3>
          <p>
            These Terms constitute the entire agreement between you and AiNDREA
            regarding the use of the website and services.
          </p>
          <h3 className="text-lg font-medium mt-4">9.2 Severability</h3>
          <p>
            If any provision of these Terms is found to be invalid or
            unenforceable, the remaining provisions will remain in full force
            and effect.
          </p>
          <h3 className="text-lg font-medium mt-4">9.3 Waiver</h3>
          <p>
            Failure to enforce any right or provision of these Terms will not be
            considered a waiver of those rights.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-2">Changes to Terms</h2>
          <p>
            We may modify these Terms at any time. We will notify you of
            significant changes by posting the updated Terms on this page,
            updating the "Last updated" date at the top, and providing notice
            through other means if appropriate. Your continued use of the
            website after changes become effective signifies your acceptance of
            the new Terms.
          </p>
        </section>

        <section className="mt-8">
          <h2 className="text-xl font-semibold mb-2">Contact Us</h2>
          <p>
            If you have any questions or concerns about these Terms, please
            contact us at:
          </p>
          <p>
            <strong>Email:</strong> team@aindrea.ai
          </p>
        </section>
      </section>
      <Footer />
    </>
  );
}

export default TermsAndConditions;
