import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import BreadCrumb from "../common/BreadCrumb";
import FAQSection from "../SupportPage/FAQSection";
import SearchArticles from "../SupportPage/SearchArticles";
import SubmitForm from "../SupportPage/SubmitForm";

export default function SupportCont() {
  return (
    <>
      <Navbar />
      <BreadCrumb title="Support Center" img={"/assets/images/support.webp"} />
      <FAQSection />
      <SearchArticles />
      <SubmitForm />
      <Footer />
    </>
  );
}
